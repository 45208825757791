<template>
  <a-modal
    centered
    :width="500"
    :closable="false"
    :footer="null"
    :keyboard="false"
    :mask-closable="false"
    :visible="visible"
  >
    <h4>Internal - {{ $t('order.cancel_order') }}</h4>
    <div class="ant-alert ant-alert-error ant-alert-with-description ant-alert-no-icon py-2 my-3">
      <b>{{ $t('order.orderNumber') }}</b> : {{ data.order_number }}
    </div>
    <a-spin :spinning="loadingCheckFulfillment" tip="Mohon Tunggu...">
      <template v-if="isEligibleCancel">
        <a-form-model
          ref="ruleForm"
          novalidate
          :model="form"
        >
          <fieldset :disabled="loading">
            <label class="font-weight-bold mb-1">{{ $t('order.cancel_detail') }}<sup class="text-danger">*</sup></label>
            <a-form-model-item prop="reason" :rules="[{ required: true }]">
              <a-textarea
                v-model="form.reason"
                allow-clear
                :auto-size="{ minRows: 4, maxRows: 10 }"
                :max-length="MAX_DETAIL"
                :disabled="loading"
                :placeholder="$t('order.placeholder_cancel_detail')"
              />
              <small class="d-block text-right text-muted mt-n1" style="line-height:1">
                {{ form.reason?.length || 0 }} / {{ MAX_DETAIL }} · {{ $t('order.info_cancel_detail', { value: 15 }) }}
              </small>
            </a-form-model-item>
            <div class="d-flex">
              <a-button
                size="large"
                class="w-50 mr-1"
                :disabled="loading"
                @click="onCancel"
              >
                {{ $t('utils.close') }}
              </a-button>
              <a-button
                size="large"
                type="primary"
                html-type="submit"
                class="w-50 ml-1"
                :loading="loading"
                :disabled="(
                  form.reason?.length < (((data?.channel?.code === 'distributor_redeem' || data?.channel?.code === 'distributor') && data?.sales_state === 'CANCELREQUESTED') ? 0 : 15))
                  || (form.reason?.length > 200)"
                @click="onSubmit"
              >
                Ya, Batalkan Pesanan
              </a-button>
            </div>
          </fieldset>
        </a-form-model>
      </template>
      <template v-else>
        <a-alert message="Maaf, nomor order ini tidak dapat dibatalkan, karena proses fulfillment sedang berlangsung." type="error" show-icon />
        <div class="text-right">
          <a-button
            size="large"
            type="primary"
            class="mt-4"
            :disabled="loading"
            @click="onCancel"
          >
            OK
          </a-button>
        </div>
      </template>
    </a-spin>
  </a-modal>
</template>

<script>
import { getOrderCatalog } from '@/api/product'
import { getFinanceDetail } from '@/api/channels/distributor'
import { getCancelReason, getCmCancelReason } from '@/api/channels/index'
import PcaImage from '@/components/Image'
import ChannelsConstants from '@/constants/channels'
import { getFulfillmentDetailByOrder } from '@/api/fulfillment'

export default {
  components: {
    PcaImage,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      OPTIONS: [
        { description: 'Stok Kosong', code: 'OUT_OF_STOCK' }, // value ???
        { description: 'Permintaan Pembeli', code: 'CANCELLED_BY_USER' },
        { description: 'Lainnya', code: 'OTHER' }, // value ???
      ],
      MAX_DETAIL: 200,
      form: {
        reason_code: null,
        oos_line_item_ids: [],
        reason: '',
      },
      reasonCancel: [],
      sales_items: [],
      isLoading: false,
      controllerFulfillment: null,
      fulfillmentData: null,
      loadingCheckFulfillment: false,
    }
  },
  computed: {
    isEligibleCancel() {
      const currentChannelCode = this.data?.channel?.sales_channel?.code
      if (this.fulfillmentData) {
        return Boolean(['CREATED', 'ACCEPTED'].includes(this.fulfillmentData?.state?.toUpperCase()))
      } else {
        return true
      }
    },
  },
  watch: {
    data(value) {
      this.sales_items = value.sales_items
      this.getCatalogue()
    },
    visible(value) {
      if (value) {
        this.fetchFulfillmentDetail()
        if(this.$refs.ruleForm) this.$refs.ruleForm.resetFields()
        // if (this.data?.channel?.code === 'distributor_redeem' || this.data?.channel?.code === 'distributor') {
        //   if (this.data?.sales_state === 'CANCELREQUESTED') {
        //     this.getDetailOrder()
        //   } else {
        //     this.reasonCancel = [].concat(this.OPTIONS)
        //   }
        // } else {
        //   if (ChannelsConstants.includes(this.data?.channel?.code)) {
        //     this.cancellationReason()
        //   } else {
        //     this.getCancelReasonCM()
        //   }
        // }
      }
    },
  },
  mounted() {
  },
  methods: {
    onCancel() {
      if(this.$refs.ruleForm) this.$refs.ruleForm.resetFields()
      this.$emit('cancel')
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        const { channel, order_number } = this.data
        const newValues = {
          orderNumber: order_number,
          channelId: channel.id,
          reason: this.form.reason,
        }
        valid && this.$emit('ok', newValues)
      })
    },
    async getDetailOrder() {
      const { id, channel } = this.data
      const { business_id } = this.$route.query
      await getFinanceDetail({
        order_id: id,
        channel_id: channel.id,
        business_id: business_id || this.$store.state.user.businessList[0].business_id,
      })
      .then(({ data: { data: response } }) => {
        const parseSelectedReason = {
          code: 'CANCELLED_BY_USER',
          description: response?.reason,
        }
        this.form.reason_code = parseSelectedReason.code
        this.reasonCancel = [parseSelectedReason]
      })
      .catch(() => {})
    },
    async cancellationReason() {
      let business_id = this.$route.query.business_id
      if (!business_id) {
        business_id = this.$store.state.user?.businessList?.find(
          val => val.warehouse_id === this.$route.query?.warehouse_id,
        )?.business_id
      }
      this.isLoading = true
      await getCancelReason({
        channel_code: this.data?.channel?.code,
        business_id,
        params: {
          order_id: this.data.id,
          order_number: this.data.order_number,
        },
        })
        .then(({ data }) => {
          this.isLoading = false
          this.reasonCancel = data?.data
            ? data.data.map(reason => ({
              ...reason,
              code: `${reason.code}#${reason.id}`,
            }))
            : this.OPTIONS.map((option, index) => ({
                id: index,
                code: `${option.code}#${index}`,
                description: option.description,
                is_default_cancel_requested: false,
              }))
          const defaultCancelReason = this.reasonCancel.find(item => item.is_default_cancel_requested === true)
          this.form.reason_code = this.data.sales_state === 'CANCELREQUESTED'
            ? `${defaultCancelReason?.code}#${defaultCancelReason?.id}`
            : null
          if(this?.data?.channel?.code === 'tokopedia_id' && this.data?.sales_state !== 'CANCELREQUESTED') {
            this.reasonCancel = this.reasonCancel.filter(item => item.code !== '8')
          }
        })
        .catch((err) => {
          this.isLoading = false
          this.reasonCancel = this.OPTIONS.map((option, index) => ({
            id: index,
            code: `${option.code}#${index}`,
            description: option.description,
            is_default_cancel_requested: false,
          }))
          // this.$notification.destroy()
          // this.$notification.error({
          //   message: err.response.data.message,
          // })
        })
    },
    async getCancelReasonCM() {
      let business_id = this.$route.query.business_id
      if (!business_id) {
        business_id = this.$store.state.user?.businessList?.find(
          val => val.warehouse_id === this.$route.query?.warehouse_id,
        )?.business_id
      }

      this.isLoading = true
      await getCmCancelReason({
        channel_code: this.data?.channel?.code,
        order_number: this.data.order_number,
        business_id,
        })
        .then(({ data }) => {
          this.isLoading = false
          this.reasonCancel = data?.data
            ? data.data
            : this.OPTIONS.map((option, index) => ({
                id: index,
                code: option.code,
                description: option.description,
                is_default_cancel_requested: false,
              }))
        })
        .catch((err) => {
          this.isLoading = false
          this.reasonCancel = this.OPTIONS.map((option, index) => ({
            id: index,
            code: option.code,
            description: option.description,
            is_default_cancel_requested: false,
          }))
          // this.$notification.error({
          //   message: err.response.data.message,
          // })
        })
    },
    getCatalogue() {
      this.loadingDetail = true
      const promise = []
      const { business_id } = this.$route.query

      const catalog_ids = (this.sales_items || []).map((item) => item.catalog_id)
      if (catalog_ids.length) {
        this.controllerOrderCatalog = new AbortController()
        promise.push(
          getOrderCatalog({
            signal: this.controllerOrderCatalog.signal,
            params: {
              business_id,
              catalogId: catalog_ids.join(','),
            },
          })
          .then(res => {
            const data = res?.data?.data || []
            this.sales_items = this.sales_items.map((item) => {
              const catalogs = data.find((val) => val.id === item.catalog_id)
              return {
                ...item,
                title: catalogs?.title || item.title,
                img_url: catalogs?.images?.[0] ? catalogs.images[0]?.imagePath || catalogs.images[0] : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531',
              }
            })
          })
          .catch(() => {})
          // eslint-disable-next-line
          .finally(() => { this.controllerOrderCatalog = null })
        )
      }
    },
    fetchFulfillmentDetail() {
      const { business_id } = this.$route.query
      const bussinesIdbyList = this.data?.business?.business_id
      const selectedIdData = this.data?.id
      if (selectedIdData) {
        this.controllerFulfillment = new AbortController()
        this.loadingCheckFulfillment = true
        getFulfillmentDetailByOrder({
          signal: this.controllerFulfillment.signal,
          business_id: bussinesIdbyList || business_id,
          order_id: selectedIdData,
        })
          .then(({ data: { data: response } }) => {
            if (response?.fulfillments?.length > 0) {
              this.fulfillmentData = response.fulfillments.at(0)
            } else {
              this.fulfillmentData = null
            }
          })
          .catch(() => {
            this.fulfillmentData = null
          })
          .finally(() => {
            this.controllerFulfillment = null
            this.loadingCheckFulfillment = false
          })
      }
    },
  },
}
</script>
