<template>
  <a-modal
    :closable="false"
    :footer="null"
    :dialog-style="{ top: '15px' }"
    :visible="showModal"
    @cancel="$emit('input', false)"
  >
    <a-form-model
      ref="formRef"
      :model="form"
    >
      <h3 class="mb-3">
        Filter
      </h3>

      <template v-if="!$route.query.business_id && getUserData.restriction_base !== 'Warehouse'">
        <label>{{ $t('order.business') }}</label>
        <a-form-model-item>
          <a-select
            v-model="form.business"
            class="w-100"
            mode="multiple"
            show-arrow
            :get-popup-container="() => $refs.formRef.$el"
            :not-found-content="$t('utils.not_found')"
            :placeholder="$t('utils.choose') + ' ' + $t('order.business')"
            :filter-option="filterOption"
          >
            <a-select-option v-for="bs in options_business" :key="bs.value">
              {{ bs.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </template>

      <template v-if="$route.path === '/sales'">
        <label>{{ $t('order.channel') }}</label>
        <a-form-model-item>
          <a-select
            v-model="form.channel"
            class="w-100"
            show-arrow
            allow-clear
            mode="single"
            :get-popup-container="() => $refs.formRef.$el"
            :not-found-content="loadChannel ? undefined : null"
            :placeholder="$t('utils.choose') + ' ' + $t('order.channel')"
            :filter-option="filterOption"
            @focus="$emit('onFocusChannel')"
            @blur="$emit('onBlurChannel')"
          >
            <a-spin v-if="loadChannel" slot="notFoundContent" size="small" />
            <a-select-option key="all" value="all">
              {{ $t('utils.all') }} Channel
            </a-select-option>
            <a-select-option v-for="channel in optionsChannel" :key="channel.value">
              <div class="d-flex align-items-center justify-content-between">
                <span class="mr-2 w-75 text-truncate">{{ channel.label }}</span>
                <a-tag>{{ channel.channelName }}</a-tag>
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </template>

      <template v-if="!$route.query.warehouse_id || getUserData.restriction_base !== 'Warehouse'">
        <label>{{ $t('order.warehouse') }}</label>
        <a-form-model-item>
          <a-select
            v-model="form.warehouse"
            class="w-100"
            show-arrow
            mode="multiple"
            :get-popup-container="() => $refs.formRef.$el"
            :not-found-content="loadWarehouse ? undefined : $t('utils.not_found')"
            :placeholder="$t('utils.choose') + ' ' + $t('order.warehouse')"
            :filter-option="filterOption"
            @focus="$emit('onFocusWarehouse')"
            @blur="$emit('onBlurWarehouse')"
          >
            <a-spin v-if="loadWarehouse" slot="notFoundContent" size="small" />
            <a-select-option v-for="wh in options_warehouse" :key="wh.value">
              {{ wh.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </template>

      <div class="mt-3">
        <label>{{ $t('order.ordersPeriod') }}</label>
        <a-form-model-item prop="date" :rules="[{ required: true, message: $t('order.require_order_period') }]">
          <a-range-picker
            v-model="form.date"
            class="w-100"
            :disabled-date="disabledDate"
            :placeholder="[$t('utils.choose') + ' ' + $t('order.ordersPeriodFrom'), $t('utils.choose') + ' ' + $t('order.ordersPeriodUntil')]"
            :allow-clear="false"
            :get-calendar-container="() => $refs.formRef.$el"
            :show-time="{ format: 'HH:mm:ss' }"
            format="YYYY-MM-DD HH:mm:ss"
            @change="onChangeDate"
          >
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-form-model-item>
      </div>

      <div class="d-flex">
        <a-button @click="resetFilter">
          {{ $t('order.cleanFilter') }}
        </a-button>
        <a-button class="ml-auto" @click="cancelModal">
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button type="primary" class="ml-2" @click="submitFilter">
          {{ $t('utils.apply') }}
        </a-button>
      </div>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    businessValue: {
      type: Array,
      default: () => [],
    },
    channelValue: {
      type: Array,
      default: () => [],
    },
    warehouseValue: {
      type: Array,
      default: () => [],
    },
    customerValue: {
      type: String,
      default: '',
    },
    courierValue: {
      type: String,
      default: '',
    },
    orderPeriod: {
      type: Array,
      default: () => [this.$moment().subtract(30, 'days').startOf('day').format(), this.$moment().endOf('day').format()],
    },
    optionsChannel: {
      type: Array,
      default: () => [],
    },
    loadWarehouse: {
      type: Boolean,
      default: false,
    },
    loadChannel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'submit', 'onFocusChannel', 'onBlurChannel', 'onFocusWarehouse', 'onBlurWarehouse'],
  data() {
    return {
      form: {
        business: [],
        channel: [],
        warehouse: [],
        date: [this.$moment().subtract(30, 'days').startOf('day').format(), this.$moment().endOf('day').format()],
        customer: '',
        courier: '',
        firstTimeChangeDate: false,
      },
    }
  },
  computed: {
    options_business() {
      let businessList = []
      this.$store.state?.user?.businessList?.forEach(business => {
        if(business.business_id !== 'all') {
          businessList.push({
            value: business.business_id, // business_id | warehouse_id
            label: business.business_name || business.name,
          })
        }
      })
      return businessList
    },
    options_warehouse() {
      return this.$store.state?.warehouse?.warehouse?.length
        ? this.$store.state.warehouse.warehouse.map(warehouse => ({
            value: warehouse.id,
            label: warehouse.name,
          }))
        : []
    },
    getUserData() {
      return this.$store.state?.user || {}
    },
  },
  watch: {
    showModal(val) {
      if(val) {
        const channelValueParse = Array.isArray(this.channelValue) && this.channelValue.length === 0 ? 'all' : this.channelValue
        this.firstTimeChangeDate = false
        let finalDate = this.orderPeriod
        if (finalDate.includes('all')) {
          finalDate = undefined
        }
        this.form = {
          business: this.businessValue,
          channel: channelValueParse,
          warehouse: this.warehouseValue,
          customer: this.customerValue,
          courier: this.courierValue,
          date: finalDate,
        }
      }
    },
  },
  methods: {
    onChangeDate(date) {
      if (!this.firstTimeChangeDate) {
        this.firstTimeChangeDate = true
        this.form.date = [this.$moment(date[0]).startOf('day').format(this.formatDate), this.$moment(date[1]).endOf('day').format(this.formatDate)]
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    cancelModal() {
      this.resetFields()
      this.$emit('close')
    },
    disabledDate (current) {
      return current && current < this.$moment().subtract(91, 'day')
    },
    resetFilter() {
      this.form = {
        business: [],
        channel: [],
        warehouse: [],
        customer: '',
        courier: '',
        date: [this.$moment().subtract(30, 'days').startOf('day').format(), this.$moment().endOf('day').format()],
      }
      this.resetFields()
    },
    resetFields() {
      if(this.$refs.formRef) this.$refs.formRef.resetFields()
    },
    submitFilter() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          let payload = this.form
          if (payload.channel === 'all') payload.channel = null
          this.$emit('submit', payload)
        }
      })
    },
  },
}
</script>