<template>
  <a-modal :width="600" :visible="visible" :wrap-class-name="$style.modalSync" :closable="false">
    <template>
      <h5 class="mt-2">
        Sync Order Channel
      </h5>
      <a-spin :spinning="isLoadingPendingSyncOrder || isLoading" class="w-100 mt-4">
        <template v-if="!isLoadingPendingSyncOrder && getSalesCode === 'shopee_id'">
          <template v-if="resStatusSyncOrder?.status === 'ON_PROCESS'">
            <p class="mt-5">
              Saat ini sedang ada <b>{{ resStatusSyncOrder.process_ids?.length }}</b> proses sync order yang sedang berjalan. Centang <b>checkbox</b> berikut jika anda tetap ingin melakukan sync order lainnya.
            </p>
            <a-checkbox v-model="form.forceSync">
              Tetap melakukan sync order
            </a-checkbox>
          </template>
          <a-tabs v-if="form.forceSync || resStatusSyncOrder?.status !== 'ON_PROCESS'" v-model="selectedTabs" class="mt-2">
            <a-tab-pane key="number" tab="Sync Order dg Nomor Order">
              <a-select
                v-model="form.orderNumber"
                mode="tags"
                style="width: 100%"
                :token-separators="[',', ' ']"
                allow-clear
                placeholder="Masukan nomor order"
              >
                <template slot="notFoundContent">
                  Masukan nomor order, lalu tekan "enter" atau ","
                </template>
              </a-select>
            </a-tab-pane>
            <a-tab-pane key="date" tab="Sync Order dg Tanggal Order">
              <a-range-picker
                class="mt-2 w-100"
                :ranges="ranges"
                :format="dateFormat"
                :value="form.date"
                :placeholder="[
                  $t('dashboardContent.start_date'),
                  $t('dashboardContent.end_date'),
                ]"
                :disabled-date="disabledDate"
                :show-time="{ format: 'HH:mm:ss' }"
                allow-clear
                @change="onChangeDate"
              >
                <DateIcon slot="suffixIcon" style="color: #999999" />
              </a-range-picker>
              <p v-if="invalidDateRange" class="text-danger mt-2">
                Jarak maksimal tanggal order adalah {{ maxDateRange }} hari.
              </p>
            </a-tab-pane>
          </a-tabs>
        </template>

        <template v-else-if="!isLoadingPendingSyncOrder && getSalesCode === 'tokopedia_id'">
          <label class="mt-5">Tanggal Order</label>
          <a-range-picker
            class="mt-2 w-100"
            :ranges="ranges"
            :format="dateFormat"
            :value="form.date"
            :placeholder="[
              $t('dashboardContent.start_date'),
              $t('dashboardContent.end_date'),
            ]"
            :disabled-date="disabledDate"
            :show-time="{ format: 'HH:mm:ss' }"
            allow-clear
            @change="onChangeDate"
          >
            <DateIcon slot="suffixIcon" style="color: #999999" />
          </a-range-picker>
          <p v-if="invalidDateRange" class="text-danger mt-2">
            Jarak maksimal tanggal order adalah {{ maxDateRange }} hari.
          </p>
        </template>

        <template v-else-if="!isLoadingPendingSyncOrder && getSalesCode === 'tiktok'">
          <label class="mt-5">Tanggal Order</label>
          <a-range-picker
            class="mt-2 w-100"
            :ranges="ranges"
            :format="dateFormat"
            :value="form.date"
            :placeholder="[
              $t('dashboardContent.start_date'),
              $t('dashboardContent.end_date'),
            ]"
            :disabled-date="disabledDate"
            :show-time="{ format: 'HH:mm:ss' }"
            allow-clear
            @change="onChangeDate"
          >
            <DateIcon slot="suffixIcon" style="color: #999999" />
          </a-range-picker>
          <p v-if="invalidDateRange" class="text-danger mt-2">
            Jarak maksimal tanggal order adalah {{ maxDateRange }} hari.
          </p>
        </template>

        <template v-else-if="!isLoadingPendingSyncOrder && getSalesCode === 'lazada_id'">
          <template v-if="resStatusSyncOrder?.status === 'ON_PROCESS'">
            <p class="mt-5">
              Saat ini sedang ada <b>{{ resStatusSyncOrder.process_ids?.length }}</b> proses sync order yang sedang berjalan. Centang <b>checkbox</b> berikut jika anda tetap ingin melakukan sync order lainnya.
            </p>
            <a-checkbox v-model="form.forceSync">
              Tetap melakukan sync order
            </a-checkbox>
          </template>
          <a-tabs v-if="form.forceSync || resStatusSyncOrder?.status !== 'ON_PROCESS'" v-model="selectedTabs" class="mt-2">
            <a-tab-pane key="number" tab="Sync Order dg Nomor Order">
              <a-select
                v-model="form.orderNumber"
                mode="tags"
                style="width: 100%"
                :token-separators="[',', ' ']"
                allow-clear
                placeholder="Masukan nomor order"
              >
                <template slot="notFoundContent">
                  Masukan nomor order, lalu tekan "enter" atau ","
                </template>
              </a-select>
            </a-tab-pane>
            <a-tab-pane key="date" tab="Sync Order dg Tanggal Order">
              <a-range-picker
                class="mt-2 w-100"
                :ranges="ranges"
                :format="dateFormat"
                :value="form.date"
                :placeholder="[
                  $t('dashboardContent.start_date'),
                  $t('dashboardContent.end_date'),
                ]"
                :disabled-date="disabledDate"
                :show-time="{ format: 'HH:mm:ss' }"
                allow-clear
                @change="onChangeDate"
              >
                <DateIcon slot="suffixIcon" style="color: #999999" />
              </a-range-picker>
              <p v-if="invalidDateRange" class="text-danger mt-2">
                Jarak maksimal tanggal order adalah {{ maxDateRange }} hari.
              </p>
            </a-tab-pane>
          </a-tabs>
        </template>
      </a-spin>
    </template>
    <template #footer>
      <template>
        <div class="d-flex justify-content-between align-items-center">
          <div class="w-100 align-self-end">
            <a-button key="back" :disabled="isLoading" class="mr-2" @click="typeof back !== 'undefined' && back(), resetData(), onClose()">
              Cancel
            </a-button>
            <a-button
              v-if="getSalesCode === 'shopee_id'"
              key="submit"
              :loading="isLoading"
              :disabled="isLoading || !((form.date[0] && form.date[1] && selectedTabs === 'date') || (form.orderNumber?.length && selectedTabs === 'number')) || invalidDateRange"
              type="primary"
              @click="onSubmitModalChannelShopee()"
            >
              Sync Order
            </a-button>
            <a-button
              v-if="getSalesCode === 'tokopedia_id'"
              key="submit"
              :loading="isLoading"
              :disabled="isLoading || !((form.date[0] && form.date[1])) || invalidDateRange"
              type="primary"
              @click="onSubmitModalChannelTokopedia()"
            >
              Sync Order
            </a-button>
            <a-button
              v-if="getSalesCode === 'tiktok'"
              key="submit"
              :loading="isLoading"
              :disabled="isLoading || !((form.date[0] && form.date[1])) || invalidDateRange"
              type="primary"
              @click="onSubmitModalChannelTiktok()"
            >
              Sync Order
            </a-button>
            <a-button
              v-if="getSalesCode === 'lazada_id'"
              key="submit"
              :loading="isLoading"
              :disabled="isLoading || !((form.date[0] && form.date[1] && selectedTabs === 'date') || (form.orderNumber?.length && selectedTabs === 'number')) || invalidDateRange"
              type="primary"
              @click="onSubmitModalChannelLazada()"
            >
              Sync Order
            </a-button>
          </div>
        </div>
      </template>
    </template>
  </a-modal>
</template>

<script>
import getQueryParam from '@/utils/getQueryParam'
import DateIcon from '@/components/Icons/DateV2.vue'
import { checkStatusSyncOrderChannel } from '@/api/channels/index'

export default {
  name: 'ModalSyncOrderChannel',
  components: {
    DateIcon,
  },
  mixins: [getQueryParam],
  props: {
    visible: Boolean,
    back: Function,
    onClose: Function,
    categoryState: Array,
    totalExportRowData: Number,
    isLoading: Boolean,
  },
  data() {
    return {
      ranges: {
        'Hari ini': [this.$moment().startOf('day'), this.$moment().endOf('day')],
      },
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
      form: {
        date: [],
        orderNumber: [],
        forceSync: false,
      },
      totalRowData: 0,
      channelOptions: [],
      selectedTabs: 'number',
      maxDateRange: 3,
      invalidDateRange: false,
      isLoadingPendingSyncOrder: false,
      resStatusSyncOrder: null,
    }
  },
  computed: {
    getSalesCode() {
      return this.$route.query?.channel_code
    },
  },
  watch: {
    'visible': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (!!value) {
          if (['tokopedia_id'].includes(this.getSalesCode)) {
            this.form.date = [this.$moment().subtract(1, 'days').startOf('day').format(), this.$moment().endOf('day').format()]
          } else if (['shopee_id'].includes(this.getSalesCode)) {
            this.onCheckPendingSyncOrder()
          } else if (['tiktok'].includes(this.getSalesCode)) {
            this.form.date = [this.$moment().subtract(1, 'days').startOf('day').format(), this.$moment().endOf('day').format()]
          } else if (['lazada_id'].includes(this.getSalesCode)) {
            this.onCheckPendingSyncOrder()
            this.form.date = [this.$moment().subtract(1, 'days').startOf('day').format(), this.$moment().endOf('day').format()]
          }
        }
      },
    },
    'selectedTabs': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (this.getSalesCode === 'shopee_id') {
          if (value === 'date') {
            this.form.date = [this.$moment().subtract(1, 'days').startOf('day').format(), this.$moment().endOf('day').format()]
          } else {
            this.form.date = []
          }
        }
      },
    },
    'form.date': {
      deep: true,
      immediate: true,
      handler: function (value) {
        this.invalidDateRange = this.checkDateRange(value)
      },
    },
  },
  methods: {
    disabledDate(current) {
      return current && current > this.$moment().endOf('day')
    },
    checkDateRange(dates) {
      return this.$moment(dates[1]).diff(this.$moment(dates[0]), 'days') >= this.maxDateRange;
    },
    onChangeDate(date) {
      const startDate = date[0]
        ? this.$moment(date[0]).format(this.formatDate)
        : ''
      const endDate = date[1]
        ? this.$moment(date[1]).format(this.formatDate)
        : ''
      this.form.date = [startDate, endDate]
    },
    resetData() {
      this.form.date = []
      this.form.orderNumber = []
      this.form.forceSync = false
    },
    async onCheckPendingSyncOrder() {
      this.isLoadingPendingSyncOrder = true
      const { query, params } = this.$route
      const { status, data: dataCheck } = await checkStatusSyncOrderChannel({
        business_id: query.business_id,
        channel_id: params.id,
        channel_code: query.channel_code,
      })
      if (status === 200) {
        this.resStatusSyncOrder = dataCheck.data
      } else {
        this.resStatusSyncOrder = null
      }
      this.isLoadingPendingSyncOrder = false
    },
    onSubmitModalChannelShopee() {
      let selectedData
      if (this.selectedTabs === 'date') {
        selectedData = {
          start: this.form.date[0],
          end: this.form.date[1],
          force: this.form.forceSync,
        }
      } else {
        selectedData = {
          order_ids: this.form.orderNumber,
          force: this.form.forceSync,
        }
      }
      this.$emit('handleSubmitModal', selectedData)
      this.resetData()
    },
    onSubmitModalChannelTokopedia() {
      const selectedData = {
        from_date_epoch_second: this.$moment(this.form.date[0]).unix(),
        to_date_epoch_second: this.$moment(this.form.date[1]).unix(),
      }
      this.$emit('handleSubmitModal', selectedData)
      this.resetData()
    },
    onSubmitModalChannelTiktok() {
      const selectedData = {
        from_date_epoch_second: this.$moment(this.form.date[0]).unix(),
        to_date_epoch_second: this.$moment(this.form.date[1]).unix(),
      }
      this.$emit('handleSubmitModal', selectedData)
      this.resetData()
    },
    onSubmitModalChannelLazada() {
      let selectedData
      if (this.selectedTabs === 'date') {
        selectedData = {
          start: this.form.date[0],
          end: this.form.date[1],
          force: this.form.forceSync,
        }
      } else {
        selectedData = {
          order_ids: this.form.orderNumber,
          force: this.form.forceSync,
        }
      }
      this.$emit('handleSubmitModal', selectedData)
      this.resetData()
    },
  },
}
</script>

<style lang="scss" module>
// @import './style.module.scss';
</style>
<style lang="scss">
.disclaimer-max-data {
  font-size: 12px;
}
</style>